import React, { useState } from "react";
import Message from "rc-message";
import { replace } from "gatsby";
import axios from "../../../utils/request";
import Form from "rc-field-form";
import ErrorField from "../../../components/error-field";
import Input from "../../../components/input";
import PublisherHeader from "../../../components/publish-header";
import Cursor from "../../../components/cursor";
import style from "./jointInfo.module.css";

export default ({ location }) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className={style.registerWrap}>
      <Cursor></Cursor>
      <PublisherHeader pathName={location.pathname}></PublisherHeader>
      <div className={style.applyStep}>
        <h2>联名信息</h2>
        <Form
          className={style.accountWrap}
          autoComplete="false"
          onFinish={async (values) => {
            try {
              const { data } = await axios({
                method: "put",
                headers: { "Content-Type": "application/json;charset=UTF-8" },
                url: "/designer/jointInfo",
                data: values,
              });
              if (data.success) {
                window.localStorage.setItem("jointInfo", values.jointInfo);
                replace("/designerPress/fullInfo");
              } else {
                Message.warning({
                  content: data.msg,
                });
              }
            } catch (error) {
              console.log(error);

            }
            setLoading(false);
          }}
        >
          <div className={style.fillWrap}>
            <ErrorField
              name="jointInfo"
              rules={[
                {
                  required: true,
                  message: "请输入联名设计师或联名品牌名",
                  validateTrigger: "onSubmit",
                },
              ]}
            >
              <Input placeholder="请输入联名设计师或联名品牌名" />
            </ErrorField>
          </div>
          <div className={style.showExample}>
            <h4>展示样图：</h4>
            <img src={require("../../../img/example.png")} width="822" height="426" alt=""/>
          </div>
          <button
            className={`${style.buttonStyle} ${loading ? style.grey : ""}`}
            disabled={loading}
          >
            {loading && (
              <img
                width="640"
                height="360"
                src={require("../../../img/icon_loading.png")}
                alt="icy加载"
              />
            )}
            确认
          </button>
        </Form>
      </div>
      <div className={style.blackBottom}></div>
    </div>
  );
};
